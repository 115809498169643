import { FunctionalComponent, h } from "preact";
import { Link } from "preact-router/match";
import * as style from "./style.css";
import { useState, useEffect } from "preact/hooks";

// interface DropDownProps extends LinkProps {
//     dropdown?: true;
// }

// const DropDownLink = (props: DropDownProps): preact.VNode<{}> => {
//     props.dropdown = true;
//     return Link(props);
// };

const Header: FunctionalComponent = () => {
    const [initialized, setInitialized] = useState(false);
    const [hasScrolled, setScrolled] = useState(false);

    if(typeof window != "undefined") {
        window.onscroll = (): void => {
            const checkScrolled = window.scrollY > 0; 
            if(hasScrolled != checkScrolled) {
                setScrolled(checkScrolled);

                const headerContainer = document.querySelector("." + style.headerContainer) as HTMLElement;
                headerContainer.style.background = (checkScrolled ? "var(--clr-header-primary-400)" : "");
            }
        };
    }

    useEffect(()=> {
        if(!initialized) {
            const anchors = Array.from(document.getElementsByTagName("a"));

            for(const anchor of anchors) {
                anchor.addEventListener("click", ({target}: any)=>{ window.location.href = target.pathname;} );
            }
            
            setInitialized(true);
        }
    }, []);

    return (
        <header>
            <input type="checkbox" id="navToggle" class={style.navToggle}></input>
            <div class={style.headerContainer}>
            <Link href="/"><img class={style.logo} src="/assets/RK-white-logo.svg" alt="ReelKingdom-logo"/></Link>
            <nav>
                <ul>
                    <input type="checkbox" id="productsToggle" class={style.productsToggle}></input>
                    <input type="checkbox" id="companyToggle" class={style.companyToggle}></input>
                    <li><Link activeClassName={style.active} href="/">Home</Link></li>
                    <li>
                        {/* <div class={style.productsContainer}>
                            <label for="productsToggle" class={style.productsLabel + " " + style.chevron}>
                                Products
                            </label>
                            <div class={style.productsSubNav}>
                                <ul>
                                <li><a href="/games/">Slots & More Games</a></li>
                                </ul>
                            </div>
                        </div> */}
                        <Link activeClassName={style.active} href="/games/">Games</Link>
                    </li>
                        {/* <div class={style.companyContainer}>
                            <label for="companyToggle" class={style.companyLabel + " " + style.chevron}>
                                Company
                            </label>
                            <div class={style.companySubNav}>
                                <ul>
                                <li><a href="/careers/">Careers</a></li>
                                </ul>
                            </div>
                        </div> */}
                    {/* <li><Link activeClassName={style.active} href="/careers/">Careers</Link></li> */}
                    <li><Link activeClassName={style.active} href="/news/">News</Link></li>
                    <li><Link activeClassName={style.active} href="/contact/">Contact</Link></li>
                </ul>
            </nav>
            <label for="navToggle" class={style.navToggleLabel}>
                <span>&#9776;</span>
            </label>
            </div>
        </header>
    );
};

export default Header;
